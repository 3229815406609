<template>
    <div class="min-h-screen flex flex-col justify-center items-center bg-purple-100">
        <div class="text-center mb-8">
            <img src="/logo.png" alt="Logo" class="mx-auto mb-4 w-24">
            <h1 class="text-3xl font-semibold mb-2">Create an Account</h1>
            <p class="text-lg text-gray-600">Enter your details</p>
        </div>
        <div class="w-full max-w-md">
            <input v-model="idNumber" type="text" placeholder="Matriculation Number"
                class="block w-full mb-4 p-4 rounded-lg bg-purple-200 border-none text-gray-700" />
            <input v-model="password" type="password" placeholder="Password"
                class="block w-full mb-4 p-4 rounded-lg bg-purple-200 border-none text-gray-700" />
            <select v-model="role" class="block w-full mb-4 p-4 rounded-lg bg-purple-200 border-none text-gray-700">
                <option value="" disabled selected>Select Role</option>
                <option value="student">Student</option>
                <option value="lecturer">Lecturer</option>
            </select>
            <input v-model="department" type="text" placeholder="Department"
                class="block w-full mb-4 p-4 rounded-lg bg-purple-200 border-none text-gray-700" />
            <button @click="handleSignup" :disabled="isLoading || !idNumber || !password || !role || !department"
                class="w-16 h-16 rounded-full bg-black flex items-center justify-center text-white mx-auto">
                <span class="text-xl">→</span>
            </button>
            <p v-if="errorMessage" class="text-red-500 mt-4">{{ errorMessage }}</p>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import { useMutation } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { useRouter } from 'vue-router';

const SIGNUP_MUTATION = gql`
  mutation AuthRegister($input: RegisterInput!) {
    authRegister(input: $input) {
      token {
        accessToken
      }
    }
  }
`;

export default {
    name: 'SignupPage',
    setup() {
        const idNumber = ref('');
        const password = ref('');
        const role = ref('');
        const department = ref('');
        const errorMessage = ref('');
        const isLoading = ref(false);
        const router = useRouter();

        const { mutate: authRegister } = useMutation(SIGNUP_MUTATION, {
            onError: (error) => {
                console.error('Error during signup:', error);
                isLoading.value = false;
                errorMessage.value = error.message;
            },
        });

        const handleSignup = async () => {
            errorMessage.value = '';
            isLoading.value = true;
            try {
                const data = await authRegister({
                    input: {
                        idNumber: idNumber.value,
                        password: password.value,
                        role: role.value,
                        department: department.value
                    },
                });
                console.log('Signup successful:', data);
                isLoading.value = false;
                if (data.data && data.data.authRegister && data.data.authRegister.token && data.data.authRegister.token.accessToken) {
                    const token = data.data.authRegister.token.accessToken;
                    console.log('Token received:', token);
                    localStorage.setItem('accessToken', token);
                    router.push('/app');
                } else {
                    console.error('Unexpected response structure:', data);
                    errorMessage.value = 'Unexpected response structure from server';
                }
            } catch (e) {
                console.error('Error in handleSignup:', e);
                isLoading.value = false;
                errorMessage.value = e.message;
            }
        };

        return {
            idNumber,
            password,
            role,
            department,
            handleSignup,
            errorMessage,
            isLoading,
        };
    },
};
</script>

<style scoped>
button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}
</style>
