<template>
    <div class="min-h-screen flex bg-purple-100">
        <SideBar />

        <!-- Main content -->
        <div class="w-3/4 p-8">
            <div class="flex justify-between items-center mb-8">
                <h1 class="text-3xl font-bold">View Users</h1>
            </div>
            <div class="bg-white p-8 rounded shadow-md">
                <div v-if="loading" class="text-gray-700">Loading...</div>
                <div v-else-if="error" class="text-red-500">{{ error }}</div>
                <div v-else>
                    <table class="min-w-full divide-y divide-gray-200">
                        <thead class="bg-gray-50">
                            <tr>
                                <th scope="col"
                                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    ID Number
                                </th>
                                <th scope="col"
                                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Role
                                </th>
                                <th scope="col"
                                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Created At
                                </th>
                                <th scope="col"
                                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Updated At
                                </th>
                            </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200">
                            <tr v-for="user in users" :key="user.id">
                                <td class="px-6 py-4 whitespace-nowrap">
                                    {{ user.idNumber }}
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap">
                                    {{ user.role }}
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap">
                                    {{ formatDate(user.createdAt) }}
                                </td>
                                <td class="px-6 py-4 whitespace-nowrap">
                                    {{ formatDate(user.updatedAt) }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, watchEffect } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import SideBar from './SideBar.vue';

const GET_USERS_QUERY = gql`
 query {
  users {
    users {
      idNumber
      id
      updatedAt
      createdAt
      role
    }
  }
}
`;

export default {
    name: 'ViewUsers',
    components: {
        SideBar
    },
    setup() {
        const { result, loading, error } = useQuery(GET_USERS_QUERY);
        const users = ref([]);
        watchEffect(() => {
            console.log(result.value)
            if (result.value && result.value.users) {
                users.value = result.value.users.users;
            }
        });

        // Function to format timestamps
        const formatDate = (timestamp) => {
            var date = new Date(Number(timestamp));

            var year = date.getFullYear();
            var month = ('0' + (date.getMonth() + 1)).slice(-2);
            var day = ('0' + date.getDate()).slice(-2);
            var hours = ('0' + date.getHours()).slice(-2);
            var minutes = ('0' + date.getMinutes()).slice(-2);

            const formattedDate = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes 
            return formattedDate
        };




        return {
            users,
            loading,
            error: error.value ? error.value.message : null,
            formatDate
        };
    }
};
</script>

<style scoped>
/* Add your custom styles here */
</style>
