<template>
    <div class="min-h-screen flex bg-purple-100">
        <SideBar />
        <div class="min-h-screen flex flex-col items-center bg-purple-100 p-8">
            <div class="w-full max-w-md bg-white rounded-lg shadow-md p-6">
                <h1 class="text-2xl font-bold mb-4">Create New Project</h1>
                <form @submit.prevent="handleSubmit">
                    <div class="mb-4">
                        <label class="block text-gray-700 text-sm font-bold mb-2" for="student_fullname">
                            Student Fullname
                        </label>
                        <input v-model="student_fullname" id="student_fullname" type="text"
                            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            required>
                    </div>
                    <div class="mb-4">
                        <label class="block text-gray-700 text-sm font-bold mb-2" for="topic">
                            Topic
                        </label>
                        <input v-model="topic" id="topic" type="text"
                            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            required>
                    </div>
                    <div class="mb-4">
                        <label class="block text-gray-700 text-sm font-bold mb-2" for="year">
                            Year
                        </label>
                        <input v-model="year" id="year" type="text"
                            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            required>
                    </div>
                    <div class="mb-4">
                        <label class="block text-gray-700 text-sm font-bold mb-2" for="document">
                            Document (PDF)
                        </label>
                        <input @change="handleFileChange" id="document" type="file" accept="application/pdf"
                            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            required>
                    </div>
                    <div class="flex items-center justify-between">
                        <button type="submit"
                            class="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                            Submit
                        </button>
                    </div>
                </form>
                <div v-if="loading" class="mt-4 text-center">
                    <p class="text-purple-700">Submitting...</p>
                </div>
                <div v-if="errorMessage" class="mt-4 text-center text-red-500">
                    <p>{{ errorMessage }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SideBar from './SideBar.vue';
import { ref } from 'vue';
import { useMutation } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { useRouter } from 'vue-router';
const ADD_PROJECT_MUTATION = gql`
  mutation AddProject($input: AddProject!) {
    addProject(input: $input) {
      createdAt
      updatedAt
      uploadedById
      student_fullname
      department
      year
      project_public_id
      project_url
    }
  }
`;

export default {
    name: 'CreateProjectPage',
    components: {
        SideBar
    },
    setup() {
        const student_fullname = ref('');
        const topic = ref('');
        const year = ref('');
        const document = ref(null);
        const loading = ref(false);
        const errorMessage = ref('');
        const router = useRouter();

        const { mutate: addProject } = useMutation(ADD_PROJECT_MUTATION, {
            onError: (error) => {
                loading.value = false;
                errorMessage.value = error.message;
            },
            onCompleted: (data) => {
                loading.value = false;
                console.log('Project added successfully:', data);
            }
        });

        const handleFileChange = (event) => {
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.onloadend = () => {
                document.value = reader.result.split(',')[1]; // Get the base64 string
            };
            reader.readAsDataURL(file);
        };

        const handleSubmit = async () => {
            if (!document.value) {
                errorMessage.value = 'Please upload a document.';
                return;
            }
            loading.value = true;
            errorMessage.value = '';
            try {
                await addProject({
                    input: {
                        student_fullname: student_fullname.value,
                        topic: topic.value,
                        year: year.value,
                        document: document.value,
                    }
                });
                router.push('/app');
            } catch (e) {
                loading.value = false;
                errorMessage.value = e.message;
            }
        };

        return {
            student_fullname,
            topic,
            year,
            document,
            loading,
            errorMessage,
            handleFileChange,
            handleSubmit,
        };
    }
};
</script>

<style scoped>
/* Add your custom styles here */
</style>
