<template>
    <div class="min-h-screen flex bg-purple-100">
        <SideBar />

    <!-- Main content -->
    <div class="w-3/4 p-8">
        <div class="flex justify-between items-center mb-8">
            <h1 class="text-3xl font-bold">Projects for {{ department }}</h1>
            <div class="flex items-center">
                <button @click="isSearchModalVisible = !isSearchModalVisible"
                    class="p-2 rounded border mr-4">Search</button>

                <div class="flex items-center">
                    <img :src="user?.profileImageUrl || 'default_profile_image.png'" alt="Profile Image"
                        class="w-10 h-10 rounded-full mr-2" />
                    <div>
                        <p class="font-bold">{{ user?.idNumber }}</p>
                        <p class="text-sm text-gray-600">{{ user?.role }}</p>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="loadingProjects" class="text-center mb-8">
            <h1 class="text-3xl font-semibold mb-2">Loading projects...</h1>
        </div>
        <div v-else-if="projectsError" class="text-center mb-8">
            <h1 class="text-3xl font-semibold mb-2 text-red-500">Error loading projects</h1>
            <p class="text-lg text-gray-600">{{ projectsError.message }}</p>
        </div>
        <div v-else>
            <div v-for="project in projects" :key="project.id"
                class="mb-4 p-4 bg-white rounded shadow flex justify-between items-center cursor-pointer"
                @click="viewProject(project.id)">
                <div>
                    <h2 class="text-xl font-semibold">{{ project.topic }}</h2>
                    <p class="text-gray-600">{{ project.department }}</p>
                    <p class="text-gray-600">By: {{ project.student_fullname }}</p>
                </div>
                <span class="text-purple-600 text-2xl">&rarr;</span>
            </div>
        </div>
        <div v-if="isSearchModalVisible"
            class="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-800 bg-opacity-50">
            <div class="bg-white p-8 rounded">
                <h2 class="text-xl font-bold mb-4">Search Projects</h2>
                <input v-model="searchInput.department" type="text" placeholder="Department"
                    class="p-2 mb-2 rounded border" />
                <input v-model="searchInput.name" type="text" placeholder="Name" class="p-2 mb-2 rounded border" />
                <input v-model="searchInput.topic" type="text" placeholder="Topic" class="p-2 mb-2 rounded border" />
                <input v-model="searchInput.year" type="text" placeholder="Year" class="p-2 mb-2 rounded border" />
                <div class="flex justify-end">
                    <button @click="searchProjects" class="px-4 py-2 bg-blue-500 text-white rounded">Search</button>
                    <button @click="closeSearchModal"
                        class="px-4 py-2 bg-gray-500 text-white rounded ml-4">Cancel</button>
                </div>
            </div>
        </div>
    </div>
    </div>
    
</template>

<script>
import { ref, watchEffect } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import { useRoute, useRouter } from 'vue-router';
import gql from 'graphql-tag';
import SideBar from './SideBar.vue';
const SEARCH_PROJECTS_QUERY = gql`
  query SearchProjects($input: SearchInput!) {
    searchProject(input: $input) {
      createdAt
      department
      id
      project_url
      student_fullname
      topic
      uploadedById
      updatedAt
      year
    }
  }
`;

export default {
    name: 'DepartmentProjects',
    components: {
        SideBar
    },
    setup() {
        const route = useRoute();
        const router = useRouter();
        const department = route.params.department;
        const user = ref(null);
        const searchInput = ref({
            department: department,
            name: "",
            topic: "",
            year: ""
        });
        const { result: projectsResult, loading: loadingProjects, error: projectsError } = useQuery(SEARCH_PROJECTS_QUERY, {
            input: searchInput.value
        });

        const projects = ref([]);

        watchEffect(() => {
            if (projectsResult.value && projectsResult.value.searchProject) {
                projects.value = projectsResult.value.searchProject;
            }
        });

        const viewProject = (id) => {
            router.push({ name: 'ViewProject', params: { id } });
        };

        return {
            user,
            department,
            projects,
            loadingProjects,
            viewProject,
            projectsError,
            searchInput
        };
    },
};
</script>

<style scoped>
/* Add your custom styles here */
</style>
