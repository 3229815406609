<!-- <template>
  <div id="app" class="flex min-h-screen bg-purple-100">
    <Sidebar />
    <div class="flex-1 p-6">
      <Header />
      <ProjectList />
    </div>
  </div>
</template>

<script>
import Header from './components/Header.vue';
import Sidebar from './components/Sidebar.vue';
import ProjectList from './components/ProjectList.vue';

export default {
  name: 'App',
  components: {
    Header,
    Sidebar,
    ProjectList
  }
}
</script> -->


<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App',
}
</script>
