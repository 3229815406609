<template>
    <div class="min-h-screen flex flex-col justify-center items-center bg-purple-100">
        <div class="text-center mb-8">
            <img src="/logo.png" alt="Logo" class="mx-auto mb-4 w-24">
            <h1 class="text-3xl font-semibold mb-2">Welcome back</h1>
            <p class="text-lg text-gray-600">Enter your details</p>
        </div>
        <div class="w-full max-w-md">
            <input v-model="idNumber" type="text" placeholder="Matriculation Number"
                class="block w-full mb-4 p-4 rounded-lg bg-purple-200 border-none text-gray-700" />
            <input v-model="password" type="password" placeholder="Password"
                class="block w-full mb-4 p-4 rounded-lg bg-purple-200 border-none text-gray-700" />
            <button @click="handleLogin" :disabled="isLoading || !idNumber || !password"
                class="w-16 h-16 rounded-full bg-black flex items-center justify-center text-white mx-auto">
                <span class="text-xl">→</span>
            </button>
            <p v-if="errorMessage" class="text-red-500 mt-4">{{ errorMessage }}</p>
            <p class="mt-4 text-gray-600 text-center">Don't have an account? <a href="/signup"
                    class="text-purple-600">Sign up</a></p>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import { useMutation } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { useRouter } from 'vue-router';

const LOGIN_MUTATION = gql`
  mutation AuthLogin($input: LoginInput!) {
    authLogin(input: $input) {
      token {
        accessToken
      }
    }
  }
`;

export default {
    name: 'LoginPage',
    setup() {
        const idNumber = ref('');
        const password = ref('');
        const errorMessage = ref('');
        const isLoading = ref(false);
        const router = useRouter();
        localStorage.clear("accessToken")

        const { mutate: authLogin } = useMutation(LOGIN_MUTATION, {
            onError: (error) => {
                console.error('Error during login:', error);
                isLoading.value = false;
                errorMessage.value = error.message;
            },
            // onCompleted: (data) => {
            //     console.log('Login successful:', data);
            //     isLoading.value = false;
            //     if (data && data.authLogin && data.authLogin.token && data.authLogin.token.accessToken) {
            //         const token = data.authLogin.token.accessToken;
            //         console.log('Token received:', token);
            //         localStorage.setItem('accessToken', token);
            //         router.push('/app');
            //     } else {
            //         console.error('Unexpected response structure:', data);
            //         errorMessage.value = 'Unexpected response structure from server';
            //     }
            // },
        });

        const handleLogin = async () => {
            errorMessage.value = '';
            isLoading.value = true;
            try {
                const data = await authLogin({
                    input: {
                        idNumber: idNumber.value,
                        password: password.value,
                    },
                });
                console.log('Login successful:', data);
                isLoading.value = false;
                if (data.data && data.data.authLogin && data.data.authLogin.token && data.data.authLogin.token.accessToken) {
                    const token = data.data.authLogin.token.accessToken;
                    console.log('Token received:', token);
                    localStorage.setItem('accessToken', token);
                    router.push('/app');
                } else {
                    console.error('Unexpected response structure:', data);
                    errorMessage.value = 'Unexpected response structure from server';
                }
            } catch (e) {
                console.error('Error in handleLogin:', e);
                isLoading.value = false;
                errorMessage.value = e.message;
            }
        };

        return {
            idNumber,
            password,
            handleLogin,
            errorMessage,
            isLoading,
        };
    },
};
</script>

<style scoped>
button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}
</style>
