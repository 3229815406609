<template>
    <div class="min-h-screen flex bg-purple-100">
        <!-- SideBar -->
        <SideBar />

        <!-- Main content -->
        <div class="w-3/4 p-8">
            <div class="flex justify-between items-center mb-8">
                <h1 class="text-3xl font-bold">All Project Repositories</h1>
                <div class="flex items-center">
                    <button @click="isSearchModalVisible = !isSearchModalVisible"
                        class="p-2 rounded border mr-4">Search</button>

                    <div class="flex items-center">
                        <img :src="user?.profileImageUrl || 'https://th.bing.com/th/id/OIP.1LRUIB2OXVePxD5hQm4fqwAAAA?rs=1&pid=ImgDetMain'"
                            alt="Profile Image" class="w-10 h-10 rounded-full mr-2" />
                        <div>
                            <p class="font-bold">{{ user?.idNumber }}</p>
                            <p class="text-sm text-gray-600">{{ user?.role }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="loadingProjects" class="text-center mb-8">
                <h1 class="text-3xl font-semibold mb-2">Loading projects...</h1>
            </div>
            <div v-else-if="projectsError" class="text-center mb-8">
                <h1 class="text-3xl font-semibold mb-2 text-red-500">Error loading projects</h1>
                <p class="text-lg text-gray-600">{{ projectsError.message }}</p>
            </div>
            <div v-else>
                <div v-for="(projects, department) in groupedProjects" :key="department"
                    class="mb-4 p-4 bg-white rounded shadow flex justify-between items-center cursor-pointer"
                    @click="viewDepartment(department)">
                    <div>
                        <h2 class="text-xl font-semibold">{{ department }}</h2>
                        <p class="text-gray-600">Total Projects: {{ projects.length }}</p>
                    </div>
                    <span class="text-purple-600 text-2xl">&rarr;</span>
                </div>
            </div>
            <div v-if="isSearchModalVisible"
                class="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-800 bg-opacity-50">
                <div class="bg-white p-8 rounded">
                    <h2 class="text-xl font-bold mb-4">Search Projects</h2>
                    <input v-model="searchInput.department" type="text" placeholder="Department"
                        class="p-2 mb-2 rounded border" />
                    <input v-model="searchInput.name" type="text" placeholder="Name" class="p-2 mb-2 rounded border" />
                    <input v-model="searchInput.topic" type="text" placeholder="Topic"
                        class="p-2 mb-2 rounded border" />
                    <input v-model="searchInput.year" type="text" placeholder="Year" class="p-2 mb-2 rounded border" />
                    <div class="flex justify-end">
                        <button @click="searchProjects" class="px-4 py-2 bg-blue-500 text-white rounded">Search</button>
                        <button @click="closeSearchModal"
                            class="px-4 py-2 bg-gray-500 text-white rounded ml-4">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SideBar from './SideBar.vue';
import { ref, computed, watchEffect } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import { useRouter } from 'vue-router';
import gql from 'graphql-tag';

const ME_QUERY = gql`
  query Me {
    me {
      idNumber
      id
      updatedAt
      createdAt
      department
      role
    }
  }
`;

const SEARCH_PROJECTS_QUERY = gql`
  query SearchProjects($input: SearchInput!) {
    searchProject(input: $input) {
      createdAt
      department
      id
      project_url
      student_fullname
      topic
      uploadedById
      updatedAt
      year
    }
  }
`;

export default {
    name: 'AppPage',
    components: {
        SideBar
    },
    setup() {
        const router = useRouter();
        const user = ref(null);
        const { result, loading, error } = useQuery(ME_QUERY);

        const projects = ref([]);
        const { result: projectsResult, loading: loadingProjects, error: projectsError } = useQuery(SEARCH_PROJECTS_QUERY, {
            input: {
                department: user.value ? user.value.department : '', // Set department only when user is available
                name: "",
                topic: "",
                year: ""
            },
            skip: !user.value // Skip fetching projects when user is not available
        });

        watchEffect(() => {
            if (result.value && result.value.me) {
                user.value = result.value.me;
            }
        });

        watchEffect(() => {
            if (projectsResult.value && projectsResult.value.searchProject) {
                projects.value = projectsResult.value.searchProject;
            }
        });

        const viewDepartment = (department) => {
            router.push({ name: 'DepartmentProjects', params: { department } });
        };

        const groupedProjects = computed(() => {
            const groups = projects.value.reduce((acc, project) => {
                if (!acc[project.department]) {
                    acc[project.department] = [];
                }
                acc[project.department].push(project);
                return acc;
            }, {});
            return groups;
        });

        return {
            user,
            loading,
            error,
            projects,
            loadingProjects,
            viewDepartment,
            projectsError,
            groupedProjects
        };
    },
};
</script>

<style scoped>
/* Add your custom styles here */
</style>
