import gql from "graphql-tag";

export const GET_PROJECT_QUERY = gql`
  query GetProject($input: GetProject!) {
    getProject(input: $input) {
      id
      student_fullname
      department
      year
      topic
      uploadedById
      project_url
      uploadedBy {
        email
        role
      }
    }
  }
`;

export const DELETE_PROJECT_MUTATION = gql`
  mutation ($input: GetProject!) {
    deleteProject(input: $input) {
      id
    }
  }
`;
