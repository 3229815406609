<!-- eslint-disable no-unused-vars -->
<template>
    <div class="min-h-screen flex bg-purple-100">
        <SideBar />

        <!-- Main content -->
        <div class="w-3/4 p-8">
            <div class="flex justify-between items-center mb-8">
                <h1 class="text-3xl font-bold">Create Repository</h1>
            </div>
            <div class="bg-white p-8 rounded shadow-md">
                <!-- <div class="mb-4">
                    <label for="idNumber" class="block text-gray-700">ID Number</label>
                    <input v-model="form.idNumber" id="idNumber" type="text" class="p-2 rounded border w-full" />
                </div> -->
                <div class="mb-4">
                    <label for="department" class="block text-gray-700">Repository</label>
                    <input v-model="form.department" id="department" type="text" class="p-2 rounded border w-full" />
                </div>
                <!-- <div class="mb-4">
                    <label for="role" class="block text-gray-700">Role</label>
                    <select v-model="form.role" id="role" class="p-2 rounded border w-full">
                        <option value="student">Student</option>
                        <option value="lecturer">Lecturer</option>
                        <option value="admin">Admin</option>
                    </select>
                </div> -->
                <div class="flex justify-end">
                    <button  class="px-4 py-2 bg-blue-500 text-white rounded">Create</button>
                </div>
                <div v-if="error" class="text-red-500 mt-4">{{ error }}</div>
                <div v-if="success" class="text-green-500 mt-4">User created successfully! password: {{ password }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import { useMutation } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import SideBar from './SideBar.vue';

const CREATE_USER_MUTATION = gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      department
      idNumber
      password
      id
    }
  }
`;

export default {
    name: 'CreateUser',
    components: {
        SideBar
    },
    setup() {
        const form = ref({
            idNumber: '',
            department: '',
            role: 'student'
        });
        // eslint-disable-next-line no-unused-vars
        const { mutate: createUserMutation, onDone, onError } = useMutation(CREATE_USER_MUTATION);

        const error = ref(null);
        const success = ref(false);
        const password = ref(null)

        const createUser = () => {
            createUserMutation({ input: form.value })
                .then((res) => {
                    success.value = true;
                    error.value = null;
                    password.value = res.data.createUser.password
                })
                .catch((err) => {
                    error.value = err.message;
                    success.value = false;
                });
        };

        return {
            form,
            createUser,
            error,
            success,
            password
        };
    }
};
</script>

<style scoped>
/* Add your custom styles here */
</style>
