<template>
    <div class="w-1/4 bg-purple-200 p-4">
        <div class="mb-8">
            <!-- <img src="path_to_logo.png" alt="Logo" class="mb-4" /> -->
            <h1 class="text-xl font-bold">ADESINA'S PROJECT REPOSITORY MANAGEMENT SYSTEM</h1>
        </div>
        <nav>
            <ul>
                <!-- <li class="mb-4">
                    <router-link to="/home" class="flex items-center p-2 hover:bg-purple-300 rounded">
                        <span class="ml-2">Home</span>
                    </router-link>
                </li> -->
                <li class="mb-4">
                    <router-link to="/app" class="flex items-center p-2 bg-purple-300 rounded">
                        <span class="ml-2">All Projects</span>
                    </router-link>
                </li>
                <li class="mb-4">
                    <router-link v-if="user && user.role === 'admin'" to="/repo"
                        class="flex items-center p-2 hover:bg-purple-300 rounded">
                        <span class="ml-2">Create Repository</span>
                    </router-link>
                </li>
                <li class="mb-4">
                    <router-link v-if="user && user.role === 'admin'" to="/create-user"
                        class="flex items-center p-2 hover:bg-purple-300 rounded">
                        <span class="ml-2">Create User</span>
                    </router-link>
                    <router-link v-else-if="user && user.role !== 'student'" to="/create-project"
                        class="flex items-center p-2 hover:bg-purple-300 rounded">
                        <span class="ml-2">Create Project</span>
                    </router-link>
                </li>
                <li class="mb-4">
                    <router-link v-if="user && user.role === 'admin'" to="/users"
                        class="flex items-center p-2 hover:bg-purple-300 rounded">
                        <span class="ml-2">Users</span>
                    </router-link>
                </li>
                <li class="mb-4">
                    <router-link to="/profile" class="flex items-center p-2 hover:bg-purple-300 rounded">
                        <span class="ml-2">Profile</span>
                    </router-link>
                </li>
                <li class="mb-4">
                    <router-link to="/" class="flex items-center p-2 hover:bg-purple-300 rounded">
                        <span class="ml-2">Logout</span>
                    </router-link>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
import { ref, watchEffect } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';

const ME_QUERY = gql`
  query Me {
    me {
      idNumber
      id
      updatedAt
      createdAt
      department
      role
    }
  }
`;

export default {
    name: 'SideBar',
    setup() {
        const user = ref(null);
        const { result, loading, error } = useQuery(ME_QUERY);

        watchEffect(() => {
            if (result.value && result.value.me) {
                user.value = result.value.me;
            }
        });

        return {
            user,
            loading,
            error,
        };
    },
};
</script>

<style scoped>
/* Add your custom styles here */
</style>
