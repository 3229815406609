<template>
    <div class="min-h-screen flex bg-purple-100">
        <SideBar />
        <div class="min-h-screen flex flex-col items-center justify-center bg-purple-100 p-4">
            <div class="bg-white p-8 rounded shadow-lg w-full max-w-md">
                <h1 class="text-2xl font-bold mb-4">Profile</h1>
                <div v-if="loading" class="text-center mb-8">
                    <h2 class="text-xl font-semibold mb-2">Loading profile...</h2>
                </div>
                <div v-else-if="error" class="text-center mb-8">
                    <h2 class="text-xl font-semibold mb-2 text-red-500">Error loading profile</h2>
                    <p class="text-lg text-gray-600">{{ error.message }}</p>
                </div>
                <div v-else>
                    <div class="flex flex-col items-center mb-4">
                        <img :src="user?.profileImageUrl || 'https://th.bing.com/th/id/OIP.1LRUIB2OXVePxD5hQm4fqwAAAA?rs=1&pid=ImgDetMain'"
                            alt="Profile Image" class="w-24 h-24 rounded-full mb-2" />
                        <h2 class="text-xl font-semibold">{{ user?.idNumber }}</h2>
                    </div>
                    <div class="flex flex-col space-y-2">
                        <div class="flex justify-between">
                            <span class="font-bold">ID:</span>
                            <span>{{ user?.idNumber }}</span>
                        </div>
                        <div class="flex justify-between">
                            <span class="font-bold">Department:</span>
                            <span>{{ user?.department }}</span>
                        </div>
                        <div class="flex justify-between">
                            <span class="font-bold">Role:</span>
                            <span>{{ user?.role }}</span>
                        </div>
                        <div class="flex justify-between">
                            <span class="font-bold">Created At:</span>
                            <span>{{ new Date(parseInt(user?.createdAt)).toLocaleDateString() }}</span>
                        </div>
                        <div class="flex justify-between">
                            <span class="font-bold">Updated At:</span>
                            <span>{{ new Date(parseInt(user?.updatedAt)).toLocaleDateString() }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, watchEffect } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import SideBar from './SideBar.vue';
const ME_QUERY = gql`
  query Me {
    me {
      idNumber
      id
      updatedAt
      createdAt
      department
      role
    }
  }
`;

export default {
    name: 'ProfilePage',
    components: {
        SideBar
    },
    setup() {
        const { result, loading, error } = useQuery(ME_QUERY);
        const user = ref(null);

        watchEffect(() => {
            if (result.value && result.value.me) {
                user.value = result.value.me;
            }
        });

        return {
            user,
            loading,
            error
        };
    }
};
</script>

<style scoped>
/* Add your custom styles here */
</style>
