<template>
    <div class="min-h-screen flex bg-purple-100">
        <SideBar />
        <div class="min-h-screen flex flex-col items-center bg-purple-100 p-8">
            <div v-if="loading" class="text-center">
                <h1 class="text-3xl font-semibold mb-2">Loading project details...</h1>
            </div>
            <div v-else-if="error" class="text-center text-red-500">
                <h1 class="text-3xl font-semibold mb-2">Error loading project details</h1>
                <p class="text-lg text-gray-600">{{ error.message }}</p>
            </div>
            <div v-else class="w-full max-w-2xl bg-white rounded-lg shadow-md p-6">
                <h1 class="text-2xl font-bold mb-4">{{ project.topic }}</h1>
                <p class="mb-2"><strong>Student Name:</strong> {{ project.student_fullname }}</p>
                <p class="mb-2"><strong>Department:</strong> {{ project.department }}</p>
                <p class="mb-2"><strong>Year:</strong> {{ project.year }}</p>
                <p class="mb-2"><strong>Uploaded By:</strong> {{ project.uploadedBy.email }} ({{ project.uploadedBy.role
                    }})
                </p>
                <div class="flex items-center justify-between mt-4">
                    <a :href="project.project_url"
                        class="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        download>
                        Download PDF
                    </a>
                    <button v-if="user && user.role !== 'student'" @click="deleteProject"
                        class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                        Delete Project
                    </button>
                </div>
                <iframe :src="pdfUrl" class="w-full h-96 mt-4" frameborder="0"></iframe>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useQuery, useMutation } from '@vue/apollo-composable';
import { GET_PROJECT_QUERY, DELETE_PROJECT_MUTATION } from '@/queries';
import SideBar from './SideBar.vue';
import gql from 'graphql-tag';
const ME_QUERY = gql`
  query Me {
    me {
      idNumber
      id
      updatedAt
      createdAt
      department
      role
    }
  }
`;

export default {
    name: 'ViewProjectPage',
    components: {
        SideBar
    },


    setup() {
        const user = ref(null);
        const { result:userResult, } = useQuery(ME_QUERY);
        const route = useRoute();
        const router = useRouter();
        const projectId = route.params.id;

        const project = ref(null);
        const pdfUrl = ref('');
        const { result, loading, error } = useQuery(GET_PROJECT_QUERY, {
            input: {
                id: projectId,
            },
        });

        watchEffect(() => {
            if (result.value && result.value.getProject) {
                project.value = result.value.getProject;
                pdfUrl.value = project.value.project_url;
            }
        });

        const { mutate: deleteProjectMutation, onDone } = useMutation(DELETE_PROJECT_MUTATION);

        const deleteProject = () => {
            deleteProjectMutation({
                input: {
                    id: projectId,
                },
            });
        };

        onDone(() => {
            router.push('/app');
        });
        watchEffect(() => {
            console.log(user)
            if (userResult.value && userResult.value.me) {
                user.value = userResult.value.me;
            }
        });

        return {
            project,
            pdfUrl,
            loading,
            error,
            deleteProject,
            user
        };
    },
};
</script>

<style scoped>
/* Add your custom styles here */
</style>
