import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
} from "@apollo/client/core";

// HTTP connection to the API
const httpLink = new HttpLink({
  uri: "https://repository-q05s.onrender.com/gql",
});

// Middleware to add the authorization token to the headers
const authLink = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem("accessToken");
  operation.setContext({
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  });
  return forward(operation);
});

// Cache implementation
const cache = new InMemoryCache();

// Create the Apollo Client
const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
});

export default apolloClient;
