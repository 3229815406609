import { createApp, provide, h } from "vue";
import { ApolloClients } from "@vue/apollo-composable";
import App from "./App.vue";
import "./assets/tailwind.css";
import { createRouter, createWebHistory } from "vue-router";

import apolloClient from "./apollo";
import LoginPage from "./components/LoginPage.vue";
import AppPage from "./components/AppPage.vue";
import CreateProject from "./components/CreateProject.vue";
import ViewProject from "./components/ViewProjectPage.vue";
import ProfilePage from "./components/ProfilePage.vue";
import CreateUser from "./components/CreateUser.vue";
import DepartmentProjects from "@/components/DepartmentProjects.vue";
import ViewUsers from "@/components/ViewUsers.vue";
import SignUpPage from "@/components/SignUpPage.vue";
import CreateRepository from "@/components/CreateRepository.vue";


const routes = [
  { path: "/", component: LoginPage },
  { path: "/app", component: AppPage },
  { path: "/create-project", component: CreateProject },
  { name: "ViewProject", path: "/view/:id", component: ViewProject },
  { name: "Profile", path: "/profile", component: ProfilePage },
  { name: "ViewUsers", path: "/users", component: ViewUsers },
  { name: "SignUpPage", path: "/signup", component: SignUpPage },
  { name: "CreateRepository", path: "/repo", component: CreateRepository },
  {
    path: "/create-user",
    name: "CreateUser",
    component: CreateUser,
  },
  {
    path: "/department/:department",
    name: "DepartmentProjects",
    component: DepartmentProjects,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const app = createApp({
  setup() {
    provide(ApolloClients, {
      default: apolloClient,
    });
  },
  render: () => h(App),
});

app.use(router);
app.mount("#app");
